<template>
  <div
    ref="PlacesAutocomplete"
    :style="style"
  >
    <QSelect
      ref="autocomplete"
      :model-value="selectedPlace"
      autocomplete="off"
      :dense="dense"
      :filled="filled"
      :outlined="!filled"
      use-input
      hide-dropdown-icon
      :options="places"
      :loading="loading"
      :placeholder="selectedPlace ? '' : placeholder"
      :display-value="selectedPlace ? getLocationLabel(selectedPlace) : ''"
      @keyup.enter="fetchPlaces"
      @input-value="updateQuery"
      @update:model-value="selectPlace"
    >
      <template #append>
        <QBtn
          :label="selectedCountry.code"
          unelevated
          @click="showCountrySelect = !showCountrySelect"
        />
      </template>

      <template #option="scope">
        <QItem
          v-bind="scope.itemProps"
          v-on="scope.itemEvents"
        >
          <QItemSection v-if="$_.get(scope, 'opt.name', false)">
            <QItemLabel v-if="scope.opt.name.replace(/\, /,'&').split('&')[0] !== ''">
              {{ scope.opt.name.replace(/\, /,'&').split('&')[0] }}
            </QItemLabel>
            <QItemLabel caption>
              {{ scope.opt.name.replace(/\, /,'&').split('&')[1] }}
            </QItemLabel>
          </QItemSection>
        </QItem>
      </template>

      <template
        v-if="searchQuery"
        #no-option
      >
        <QItem>
          <QItemSection class="text-grey">
            {{ $t({ id: 'status.no_results' }) }}
          </QItemSection>
        </QItem>
      </template>
    </QSelect>
    <QSlideTransition>
      <QSelect
        v-show="showCountrySelect"
        v-model="selectedCountry"
        :options="mappedCountries"
        dense
        filled
        :display-value="selectedCountry.country"
        @update:model-value="showCountrySelect = false"
      />
    </QSlideTransition>
  </div>
</template>

<script>
import axios from 'axios'
import { getLocationLabel } from 'hc-core/composables/misc.js'

export default {
  props: {
    style: {
      type: String,
      default: 'max-width:350px'
    },
    dense: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: true
    },
    value: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Adresse, ville ...'
    },
    addInseeCode: {
      type: Boolean,
      default: false
    },
    step: {
      type: Object,
      default: null,
    },
  },
  emits: ['selectPlace', 'stepResult'],
  data () {
    return {
      loading: false,
      searchQuery: null,
      selectedPlace: null,
      selectedCountry: {
        code: 'FR',
        value: 'fr',
        country: 'France'
      },
      showCountrySelect: false,
      debouncing: false,
      places: []
    }
  },
  computed: {
    mappedCountries () {
      return this.$_.get(this.$store, 'state.common.config.custom.stripe.countries', []).map((i) => {
        return { label: i.country, code: i.code, value: i.code.toLowerCase() }
      })
    }
  },
  watch: {
    value: {
      handler: function (val, oldVal) {
        if (val !== undefined) this.selectPlace(val)
      }
    },
  },
  mounted () {
    if (this.value) this.selectedPlace = this.value
    if (this.step) this.selectPlace(this.selectedPlace)
  },
  methods: {
    getLocationLabel,
    async updateQuery (query) {
      // Manual debouncing prevent spamming queries
      this.searchQuery = query
      if (this.debouncing) return
      this.debouncing = true
      setTimeout(async () => {
        await this.fetchPlaces()
        this.debouncing = false
      }, 650)
    },
    async selectPlace (place) {
      try {
        if (place && this.addInseeCode) {
          // Add Insee code
          const odsRequest = await axios.get(`${process.env.OPENDATASOFT_URL}/?dataset=${process.env.OPENDATASOFT_DATASET_ID}&apiKey=${process.env.OPENDATASOFT_API_KEY}&q=${encodeURIComponent(place.postcode + ' ' + place.city)}`)
          this.$_.set(place, 'inseeCode', this.$_.get(odsRequest, 'data.records[0].fields.insee_com', undefined))
          if (!this.$_.get(place, 'postcode', false) || place.postcode === '-') this.$_.set(place, 'postcode', this.$_.get(odsRequest, 'data.records[0].fields.code_postal', '-'))
        }
        this.selectedPlace = place
        this.$emit('selectPlace', place)
        if (this.step) this.$emit('stepResult', { canGoNext: !!place, value: this.$_.set({}, this.step.field, place) })
      } catch (e) { this.useLogger(e) }
    },
    async fetchPlaces () {
      if (this.searchQuery.length <= 2) return
      this.loading = true
      const places = await this.searchAxios()
      this.places = places.filter((place) => place.city && place.postcode)
      this.loading = false
      if (this.$refs && this.$refs.autocomplete) this.$refs.autocomplete.showPopup()
    },

    // Implementing MapBox search API
    async searchAxios () {
      try {
        const mapboxRequest = await axios.get(`${process.env.MAPBOX_SEARCH_URL}/${this.searchQuery}.json?country=${this.selectedCountry.code}&language=fr&types=region,place,address,postcode&access_token=${process.env.MAPBOX_TOKEN}`)
        return this.$_.get(mapboxRequest, 'data.features', []).map(this.mapboxPlaceMapping)
      } catch (e) {
        this.useLogger(e)
        return []
      }
    },

    // Used to map mapbox results
    mapboxPlaceMapping (hit) {
      const displayName = this.$_.get(hit, 'place_name', '---')
      const ctx = this.$_.get(hit, 'context', [])
      return {
        id: `${hit.id}_${require('shortid').generate()}`,
        latitude: parseFloat(hit.center[1]),
        longitude: parseFloat(hit.center[0]),
        name: displayName,
        formated: displayName,
        city: this.$_.get(ctx.find(({ id }) => id.match('place')), 'text', this.$_.get(hit, 'text', '-')),
        country: this.$_.get(ctx.find(({ id }) => id.match('country')), 'text', this.$_.get(this.selectedCountry, 'label', 'France')),
        postcode: this.$_.get(ctx.find(({ id }) => id.match('postcode')), 'text', '-'),
        street: hit.place_type.includes('address') ? `${this.$_.get(hit, 'address', '')} ${this.$_.get(hit, 'text', '')}` : this.$_.get(hit, 'text', '')
      }
    },
  }
}

</script>
