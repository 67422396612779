<template>
  <QCard
    v-if="capsule && capsule.fields.url"
    class="hc-shadow br-25 overflow-hidden"
  >
    <QCardSection class="q-pa-none">
      <LazyYtVideo
        :src="embedURL(capsule.fields.url)"
        class="fit br-25-top"
        :ratio="16/9"
      />
    </QCardSection>
    <QCardSection>
      <h3 class="text-subtitle1 text-grey-7 q-ma-none">
        {{ capsule.fields.title }}
      </h3>
      <div class="text-body2 text-grey-7">
        Compta Capsule by HappyCab x VDC
      </div>
      <AppContent
        class="text-caption text-grey-5 q-mt-sm"
        path="pages.home.capsule_date"
        :options="{ date: $t({ id: 'time.date' }, { fmtd: capsule.publishedDate || capsule .createdDate }) }"
      />
    </QCardSection>
  </QCard>
</template>

<script>
import { embedURL } from 'hc-core/composables/misc.js'
import LazyYtVideo from 'hc-core/components/tools/lazy-yt-video'

export default {
  components: { LazyYtVideo },
  props: {
    capsule: {
      type: Object,
      default: null
    }
  },
  methods: {
    embedURL
  }
}
</script>
