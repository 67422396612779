<template>
  <QAvatar
    ref="AppAvatar"
    style="transition:all 0.2s ease;"
    :class="contentClass"
    :size="size"
  >
    <HCLoading v-if="loading" />
    <!-- New way with generic entity -->
    <img
      v-if="cptEntity.url"
      style="object-fit:cover; transition:all 0.2s ease;"
      width="50px"
      height="50px"
      :src="cptEntity.url"
      loading="lazy"
    >
    <div
      v-else-if="cptEntity.letter"
      class="full-height full-width text-white flex flex-center text-weight-bold"
      :style="cptEntity.color"
    >
      {{ cptEntity.letter }}
    </div>
    <!-- Placeholder -->
    <img
      v-else
      :src="cdnImg($t({ id: 'images.hc_placeholder' }))"
      loading="lazy"
    >
  </QAvatar>
</template>

<script>
import { bgGradient } from 'hc-core/composables/misc.js'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    entity: { // New generic way
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: '45px'
    },
    contentClass: {
      type: String,
      default: ''
    },
  },
  computed: {
    si () { return parseInt(this.size.replace('px', '')) },
    // Trying with a generic entity that will be lighter than all the others checks
    cptEntity () {
      const getPropertyIfExists = (e, propertyNames) => this.$_.get(e, this.$_.find(propertyNames, prop => !this.$_.isNil(this.$_.get(e, prop))), '')

      const key = getPropertyIfExists(this.entity, ['metadata._files.avatar', 'metadata._files.logo', 'company.metadata._files.logo'])
      const url = key ? this.cdnImg(key, { width: this.si, height: this.si }) : null

      const l = getPropertyIfExists(this.entity, ['firstname', 'name'])
      const letter = l ? l.substring(0, 1).toUpperCase() : null
      return { url, letter, color: this.getColor(this.entity) }
    }
  },
  methods: {
    getColor (entity) {
      if (entity) return `text-shadow: 0px 1px 2px rgb(15 20 51 / 37%); ${bgGradient(entity.id)}`
      return 'text-shadow: 0px 1px 2px rgb(15 20 51 / 37%);'
    },
  },
}
</script>

<style lang="sass" scoped>
.avatar-placeholder
  border-radius: 50%
  background: $dimmed-background
  transition: opacity ease $transition-duration
  opacity: 0
  &.avatar-placeholder--show
    opacity: 0.9

.avatar-anchor
  &:focus, &:hover
    .avatar-placeholder
      opacity: 0.9
</style>
